import { getAppConfig } from "../components/App/epics/appConfig/appConfig.js";

const appConfig = getAppConfig();

type loadChatProps = {
    ownerData?: any;
    language: string;
    position?: string;
};

const loadChat = ({ ownerData, language, position = "bl" }: loadChatProps) => {
    return new Promise<void>((resolve, reject) => {
        const maxCalls = 10;
        let numCalls = 0;
        const initChatWidget = () => {
            numCalls += 1;

            if (window.$zopim && window.zE) {
                window.zE(() => {
                    window.zE.setHelpCenterSuggestions({ search: "website builder" });
                    if (language === 'nb') {
                        // eslint-disable-next-line no-param-reassign
                        language = 'no';
                    }
                    window.zE.setLocale(language || 'en');
                });

                window.$zopim(() => {
                    const chat = window.$zopim.livechat;

                    if (ownerData) {
                        chat.setName(ownerData.customerName);
                        chat.setEmail(ownerData.email);
                    }
                    chat.button.setPosition(position);

                    const { primaryThemeColor } = appConfig.oneWeb.frontEnd.partnerConfig;

                    if (primaryThemeColor) {
                        chat.theme.setColor(primaryThemeColor);
                        chat.theme.reload();
                    }

                    resolve();
                });
            } else if (numCalls < maxCalls) {
                setTimeout(initChatWidget, 300);
            } else {
                reject();
            }
        };
        initChatWidget();
    });
};

const loadChatScript = (callback) => {
    const existingScript = document.getElementById('zendesk-chat');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://webapps.group-cdn.one/zendesk-chat-wrapper/0.2.1/widget.js';
        script.id = 'zendesk-chat';
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
};

export {
    loadChat,
    loadChatScript
};
