const blogTypes = {
    blog: "BLOG",
    category: "CATEGORY",
    post: "POST"
};

const isBlogPage = (page) => !!(page && page.subType && page.subType.containsBlog);
const isPostPage = (page) => !!(page && page.subType && page.subType.containsPost);
const isCategoryPage = (page) => !!(page && page.subType && page.subType.containsBlogCategory);
const isBlogSubPage = (page) => isPostPage(page) || isCategoryPage(page);
const isBlogRelatedPage = (page) => isBlogPage(page) || isPostPage(page) || isCategoryPage(page);

const getBlogPageType = (page) => {
    if (isBlogPage(page)) {
        return blogTypes.blog;
    }
    if (isPostPage(page)) {
        return blogTypes.post;
    }
    if (isCategoryPage(page)) {
        return blogTypes.category;
    }
    return null;
};

const getBlogDivId = (blogId) => `blog_preview_${blogId}`;
const getPostDivId = (blogId) => `post_preview_${blogId}`;

const pageType = "web.data.links.LinkPage";

const DEFAULT_BLOG_THEME = {
    mainColor: "#555088",
    accentColor: "#ffd754",
    whiteColor: "#ffffff",
    blackColor: "#000000",
};

/** @returns {string} */
const getPagePath = ({ items, requiredPageId }) => {
    // Use a stack to simulate the recursive calls
    const pageItems = items.filter(item => item.type === pageType);
    const stack = pageItems.map(item => ({
        node: item,
        path: `/${item.url}`,
    }));

    for (let i = 0; i < stack.length; i++) {
        const { node, path } = stack[i];
        // If the pageId matches, return the current path
        if (node.pageId === requiredPageId) {
            return path;
        }

        // If there are nested items, add them to the stack with updated paths
        if (node.items && node.items.length > 0) {
            for (const child of node.items) {
                if (child.type !== pageType) continue;
                stack.push({
                    node: child,
                    path: `${path}/${child.url}`,
                });
            }
        }
    }

    // If the required pageId is not found, return an empty string
    return "";
};

const extractBlogPageUrl = ({ siteMap, blogPageId, domain, isDevEnv }) => {
    const { homePageId, folder: { items } } = siteMap;
    let pagePath = getPagePath({ items, requiredPageId: blogPageId });
    if (isDevEnv) {
        return {
            blogPageUrlPrefix: `/webdav/${domain}`,
            blogPageUrl: pagePath,
            isHomePage: blogPageId === homePageId,
            pageId: blogPageId
        };
    }
    return {
        blogPageUrlPrefix: '',
        blogPageUrl: pagePath,
        isHomePage: blogPageId === homePageId,
        pageId: blogPageId
    };
};

/** @returns {string} */
const extractWebspaceAssetPrefix = ({ domain, isDev, appId, isClusterUser, canUseImproPath, improServiceConfig }) => {
    const {
        domains = [],
        enabled: improServiceEnabled,
        excludedDomains = [],
        host: improServiceHost,
    } = improServiceConfig || {};
    const useImproService = improServiceEnabled || domains.includes(domain) || isClusterUser;
    if (useImproService && !excludedDomains.includes(domain)) {
        return `${improServiceHost}/appid/${appId}/domain/${domain}/media/${domain}`;
    } else {
        const prefix = isDev ? `/webdav/${domain}` : '';
        if (canUseImproPath) {
            // TODO: remove this after the impro refactoring in publishDomain.js
            return `${[prefix]}/____impro/1`;
        }
        return prefix;
    }
};

const getBlogPagePaths = ({ siteMap, domain, isDevEnv = false }) => {
    let blogPageIds = [], result = [];
    const process = (pages) => {
        pages.forEach(page => {
            if (isBlogPage(page)) {
                blogPageIds.push(page.pageId);
            }
            if (page.items && page.items.length) {
                process(page.items);
            }
        });
    };
    process(siteMap.folder.items);
    blogPageIds.forEach(pageId => {
        result.push(extractBlogPageUrl({ siteMap, blogPageId: pageId, domain, isDevEnv }));
    });
    return result;
};

const getCurrentAndParentPage = (siteMap, pageId) => {
    let result = null, found = false;
    const process = (items, parent) => {
        if (!found && items && items.length) {
            items.forEach(item => {
                if (item.type === pageType && item.pageId === pageId) {
                    result = { parentPage: parent, currentPage: item };
                    found = true;
                    return;
                }
                process(item.items, item);
            });
        }
    }
    process(siteMap.folder.items, null);
    return result;
};

const getBlogContentPlaceholderStr = (blogId) => `replaceBlogPhpCode_${blogId}`;
const getPostContentPlaceholderStr = (blogId) => `replacePostPhpCode_${blogId}`;

const sanitizeBlogUrl = (blogUrl) => {
    const [baseUrl, queryString] = blogUrl.split('?');
    if (!queryString) {
        return blogUrl;
    }
    // Replace '&' with '&amp;' in the query string
    const updatedQueryString = queryString.replace(/&/g, '&amp;');
    // Sanitized url
    return `${baseUrl}?${updatedQueryString}`;
}

const getBlogSiteMapUrls = ({
    blogConfig,
    partnerName,
    domain,
    blogPages,
    siteMap
}) => {
    let result = [];
    const blogPagePaths = getBlogPagePaths({ siteMap, domain });
    blogPagePaths.forEach(({ isHomePage, blogPageUrl, pageId }) => {
        const page = blogPages.find(page => page.id === pageId);
        const blogCmp = page && page.items.find(cmp => cmp.type === "BLOG");
        if (blogCmp && page) {
            result.push(sanitizeBlogUrl(`${blogConfig.hostname}/public/${partnerName}/${domain}/sitemap.xml?id=${blogCmp.blogId}&path=${blogPageUrl}${isHomePage ? '&isHomePage=true' : ''}`));
        }
    });
    return result;
};

module.exports = {
    DEFAULT_BLOG_THEME,
    isBlogPage,
    isPostPage,
    isBlogSubPage,
    isCategoryPage,
    getPagePath,
    extractBlogPageUrl,
    extractWebspaceAssetPrefix,
    getBlogPagePaths,
    getBlogContentPlaceholderStr,
    getPostContentPlaceholderStr,
    getCurrentAndParentPage,
    isBlogRelatedPage,
    getBlogSiteMapUrls,
    pageType,
    getBlogPageType,
    blogTypes,
    getBlogDivId,
    getPostDivId
};

