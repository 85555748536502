import { put, take } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import

import { receiveOnly } from '../../../../epics/makeCondition';
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyValueActionType } from '../../../App/epics/siteData/valueActionType';
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import {
    LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_SUCCESS_ACTION,
    NEW_PAGE_DIALOG_OPEN,
    NEW_PAGE_LAYOUT_ADD,
    NEW_PAGE_LAYOUT_NAME_ENTERED,
    loadNewPageLayoutTemplateSetApiAction
} from "../actions";
import { takePayloadFromDialogGen } from "../../../../utils/saga";
import { NewPageNameDialogId } from "../newPageDialogIds";
import { DataSite } from "../../../../../dal/model";
import mergePageLayoutWithTemplate from "../../pagesLogic/mergePageLayoutWithTemplate";
import makeUuid from "../../../../../utils/makeUuid";
import createPageSaga from "../../saga/createPageSaga";
import getRepositoryPageDataSetSaga from "../../saga/getRepositoryPageDataSetSaga";
import type { NewPageDialogPayload, NewPageDialogLayoutPageSaveInput } from "../flowTypes";
import checkUnsavedChangesSaga from "../../../UnsavedChanges/checkUnsavedChangesSaga";
import { newLayoutPageCreated } from "../actionCreators";
import { copyPage } from '../../../../../dal/model/utils/copyPage';
import { type SiteSettings } from '../../../App/epics/siteSettings/flowTypes';
import { ROSiteSettingsSelector } from '../../../App/epics/siteSettings/selectorActionTypes';
import { getBlankPageDataSet } from "../../utils/getBlankPageDataSet";
import { DefaultLayoutCategory } from '../../utils/makeNewPageUrl';

const addLayoutPageSaga = function* (
    siteData: DataSite,
    siteSettings: SiteSettings,
    currentPageId: string,
    newPageDialogPayload: NewPageDialogPayload,
    payload: NewPageDialogLayoutPageSaveInput,
): AnyGenerator {
    const checked = yield* checkUnsavedChangesSaga();
    if (!checked) return;

    const
        { layout, template, componentsMap } = payload,
        { category: layoutCategory } = layout,
        pageName = yield* takePayloadFromDialogGen(
            NewPageNameDialogId,
            NEW_PAGE_LAYOUT_NAME_ENTERED,
            true /* replaceDialog */
        );

    // get layout data
    let layoutData;
    // if (layout.isBlank()) layoutData = new BlankDataPageSet(pageName, template.id);
    // else {
    //     layoutData = yield* getRepositoryPageDataSetSaga(layout.pageId);
    // }
    if (layout.category === DefaultLayoutCategory) {
        // @ts-ignore
        layoutData = getBlankPageDataSet(componentsMap, template.pageId, pageName);
    } else {
        const config = {
            flagAutoColorActive: (
                siteSettings.themeSettingsData &&
                siteSettings.themeSettingsData.autoColorMode
            )
        };
        layoutData = yield* getRepositoryPageDataSetSaga(layout.accountName, layout.pageId, config);
    }

    if (!layoutData) return;

    yield put(loadNewPageLayoutTemplateSetApiAction(template.id));

    const { payload: templateData } = yield take(LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_SUCCESS_ACTION),
        { copy: pageCopy } = copyPage(layoutData.page, { name: pageName });

    layoutData.page = pageCopy;

    // build new layout data
    const
        newPageId = makeUuid(),
        newLayoutData = mergePageLayoutWithTemplate(layoutData, templateData, newPageId);

    // define parent page as parent of selected page
    const
        selectedPageId = newPageDialogPayload?.selectedPage?.pageId,
        selectedPageRefParent = siteData.getPageRefByPageId(currentPageId),
        parentPageId = selectedPageId || siteData.getPageRefParentPageId(selectedPageRefParent.id);

    // create new page
    const newPageRefId = yield* createPageSaga({
        site: siteData,
        siteSettings,
        srcPage: newLayoutData.page,
        parentPageId,
        updatedTemplate: newLayoutData.template,
        layoutCategory,
        generateNewId: false /* we need to preserve page id cause of relPage reference */
    });

    yield put(newLayoutPageCreated(newPageRefId));
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyValueActionType,
    ROSiteSettingsSelector,
    receiveOnly(currentPageIdValueActionType),
    NEW_PAGE_DIALOG_OPEN,
    NEW_PAGE_LAYOUT_ADD,
    addLayoutPageSaga
);

export {
    listener as default,
    addLayoutPageSaga
};
