import utils, { DEFAULT_BLOG_THEME } from '../../../../../server/shared/blog/utils.js';
import { toHex } from '../../../mappers/color';
import { ColorGlobalData } from '../../ColorPicker/flowTypes';
import { checkComponentSubscriptionCompatibility, getComponentTierDataFromComponentKind } from '../../ComponentTierManager/utils';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';
import { getThemeColorsFromStylesheet } from '../../Workspace/epics/stylesheets/selectors';
import BlogKind from './BlogComponent/kind';
import getAccessManager from '../../../getAccessManager';

const getBlogPreviewReqData = ({
    appConfig,
    siteMap,
    websiteCategory,
    pageId,
    domain,
    blogId,
    layout,
    isServerPreview,
    locale,
    themeColors,
    selectedParentTheme
}: {
    appConfig: any;
    siteMap: any;
    websiteCategory: string;
    pageId: string;
    domain: string;
    blogId: string;
    layout: string;
    isServerPreview?: boolean;
    locale: string;
    themeColors?: any;
    selectedParentTheme?: string;
}) => {
    const { partnerName, oneWeb: { blog: { hostname } }, env } = appConfig;
    const { parentPage, currentPage } = utils.getCurrentAndParentPage(siteMap, pageId);
    let blogPage, categoryPage;
    if (currentPage.subType.containsBlogCategory) {
        blogPage = parentPage;
        categoryPage = currentPage;
    } else {
        blogPage = currentPage;
        categoryPage = blogPage.items.find(page => page.subType.containsBlogCategory);
    }
    const postPage = blogPage.items.find(page => page.subType.containsPost);
    const { isHomePage, blogPageUrlPrefix, blogPageUrl } = utils.extractBlogPageUrl({
        siteMap,
        blogPageId: blogPage.pageId,
        domain,
        isDevEnv: env === 'development'
    });

    // prefix to add for webspace assets
    const webspaceAssetPrefix = `/api/v1/${domain}/webspace`;
    const repositoryAssetPrefix = "/api/v1/repository/webspace";

    return {
        isServerPreview,
        blogPageId: blogPage.pageId,
        postPageId: postPage.pageId,
        categoryPageId: categoryPage.pageId,
        currentPageId: pageId,
        currentPageType: utils.getBlogPageType(currentPage),
        blogEltId: utils.getBlogDivId(blogId),
        blogHostName: hostname,
        partnerName,
        siteId: domain,
        themeColors,
        dataToPost: {
            layout,
            webspaceAssetPrefix,
            repositoryAssetPrefix,
            assetPrefix: hostname,
            isHomePage,
            blogPageUrlPrefix,
            blogPageUrl,
            blogId,
            locale,
            websiteCategory,
            selectedParentTheme,
        }
    };
};

const getPostPreviewReqData = ({
    appConfig,
    siteMap,
    postPageId,
    domain,
    blogId,
    isServerPreview,
    websiteCategory,
    locale,
    themeColors,
    selectedParentTheme,
}: {
    appConfig: any;
    siteMap: any;
    postPageId: string;
    domain: string;
    blogId: string;
    isServerPreview?: boolean;
    websiteCategory: string;
    locale: string;
    themeColors?: any;
    selectedParentTheme?: string;
}) => {
    const { partnerName, oneWeb: { blog: { hostname } }, env } = appConfig;
    const { parentPage: blogPage, currentPage: postPage } = utils.getCurrentAndParentPage(siteMap, postPageId);
    const categoryPage = blogPage.items.find(page => page.subType.containsBlogCategory);
    const { isHomePage, blogPageUrlPrefix, blogPageUrl } = utils.extractBlogPageUrl({
        siteMap,
        blogPageId: blogPage.pageId,
        domain,
        isDevEnv: env === 'development'
    });

    // prefix to add for webspace assets
    const webspaceAssetPrefix = `/api/v1/${domain}/webspace`;
    const repositoryAssetPrefix = "/api/v1/repository/webspace";

    return {
        isServerPreview,
        blogPageId: blogPage.pageId,
        postPageId: postPage.pageId,
        categoryPageId: categoryPage.pageId,
        postEltId: utils.getPostDivId(blogId),
        blogHostName: hostname,
        partnerName,
        siteId: domain,
        themeColors,
        dataToPost: {
            assetPrefix: hostname,
            blogPageUrl,
            isHomePage,
            blogPageUrlPrefix,
            blogId,
            locale,
            websiteCategory,
            selectedParentTheme,
            webspaceAssetPrefix,
            repositoryAssetPrefix
        }
    };
};

const getThemeColorData = (stylesheets?: Stylesheets) => {
    if (!stylesheets) {
        return undefined;
    }
    const themeColors = getThemeColorsFromStylesheet(stylesheets);
    return {
        accentColor: themeColors?.accentColor ? toHex(themeColors.accentColor) : DEFAULT_BLOG_THEME.accentColor,
        blackColor: themeColors?.blackColor ? toHex(themeColors.blackColor): DEFAULT_BLOG_THEME.blackColor,
        mainColor: themeColors?.mainColor ? toHex(themeColors.mainColor): DEFAULT_BLOG_THEME.mainColor,
        whiteColor: themeColors?.whiteColor ? toHex(themeColors.whiteColor): DEFAULT_BLOG_THEME.whiteColor,
    };
};

const getRecentColorData = (colorGlobalDataState: ColorGlobalData): string[] => {
    if (!colorGlobalDataState?.recentColors) {
        return [];
    }
    return colorGlobalDataState.recentColors.map((color) => {
        if (!color) {
            return '';
        }
        return toHex(color);
    });
};

/**
 * Returns `true` if Blog is available for this domain, `false` otherwise
 */
const isBlogEnabled = (): boolean => {
    // get the enabled flag from the access manager depending on current subscription and partner
    // which is configured in "config/common.cjson" and in partner configs e.g. "partners/onecom/config/onecom-common.cjson"
    // for local development "config/development.cjson" is used
    return getAccessManager().isBlogEnabled();
};

/**
 * Returns `true` if Blog is compatible with the subscription type, `false` otherwise
 */
const isBlogCompatibleWithSubscription = (subscriptionType: string): boolean => {
    const blogCmpTierData = getComponentTierDataFromComponentKind(BlogKind);

    return checkComponentSubscriptionCompatibility(
        blogCmpTierData.componentTierType,
        subscriptionType
    );
};

export {
    getBlogPreviewReqData,
    getPostPreviewReqData,
    getThemeColorData,
    getRecentColorData,
    isBlogEnabled,
    isBlogCompatibleWithSubscription
};
